import axios from "axios";
export var imageUrl;
export var domain;
var baseUrl1;
var baseUrl2;
if ((window.location.origin).includes('localhost')) {
  imageUrl = "http://192.168.84.59:8080"
  baseUrl1 = "http://192.168.84.59:8080/api/call";
  baseUrl2 = "http://192.168.84.59/sit-ci-api/call";
  domain = 'https://uat.zurahjewellery.com';
  // domain = 'https://uat.diamondsforapurpose.com';

  // domain = 'https://staging.zurahjewellery.com';
  // imageUrl = "https://apistaging-ecom-store.upqor.com"
  // baseUrl1 = "https://apistaging-ecom-store.upqor.com/api/call";
  // baseUrl2 = "https://apistaging-ecom.upqor.com/call";
}
else if ((window.location.origin).includes('staging')) {
  imageUrl = "https://apistaging-ecom-store.upqor.com"
  baseUrl1 = "https://apistaging-ecom-store.upqor.com/api/call";
  baseUrl2 = "https://apistaging-ecom.upqor.com/call";
  domain = window.location.origin;
}
else if ((window.location.origin).includes('uat')) {
  imageUrl = "https://apiuat-ecom-store.upqor.com"
  baseUrl1 = "https://apiuat-ecom-store.upqor.com/api/call";
  baseUrl2 = "https://apiuat-ecom.upqor.com/call";
  domain = window.location.origin;
}
else {
  imageUrl = "https://api-ecom-store.upqor.com"
  baseUrl1 = "https://api-ecom-store.upqor.com/api/call";
  baseUrl2 = "https://api-ecom.upqor.com/call";
  domain = window.location.origin;
}
class Commanservice {

  postLaravelApi(controller, obj) {
    return axios.post(baseUrl1 + controller, obj);
  }

  postApi(controller, obj, signal) {
    if (signal === undefined) {
      return axios.post(baseUrl2 + controller, obj);
    } else {
      return axios.post(baseUrl2 + controller, obj, {
        signal: signal
      });
    }
  }

  postApiFile(controller, obj) {
    return axios.post(imageUrl + controller, obj);
  }

  obj_json(obj, value) {
    const data = [];
    data["a"] = value;
    for (const pair of obj) {
      data[pair[0]] = pair[1];
    }
    const arrayToString = JSON.stringify(Object.assign({}, data));
    return arrayToString;
  }

}

export default new Commanservice();
